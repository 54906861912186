import Axios from 'axios';
import React, { useState } from 'react';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';

import Field from '../components/Form/Field';
import Main from '../components/Anatomy/Main';
import Submit from '../components/Form/Submit';
import Formik from '../components/Form/Formik';
import FormWrapper from '../components/Anatomy/FormWrapper';
import AlertWrapper from '../components/Anatomy/AlertWrapper';
import ReCaptchaContainer from '../components/ReCaptcha/ReCaptchaContainer';

import validations from '../utils/validations';
import SERVICE_LOCATIONS from '../utils/constants';

const successMessage =
  'Your form was successfully submitted. We will contact you shortly.';

const errorMessage =
  'An error occurred while submitting your form. Please try again later.';

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  serviceLocation: '',
};

const IndexPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showForm, setShowForm] = useState(true);
  const [success, setSuccess] = useState();

  const onSubmit = async (values) => {
    const captchaToken = await executeRecaptcha('appointment');

    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone_number: values.phone,
      email_address: values.email,
      service_location: values.serviceLocation.value,
      formId: process.env.GATSBY_FORM_ID || 26,
      captchaToken,
    };

    try {
      await Axios({
        url: process.env.GATSBY_API_URL,
        method: 'post',
        responseType: 'application/json',
        data,
      });

      switch (values.serviceLocation.value) {
        case 'Back & Neck Pain Center':
          window.top.location.href =
            'https://www.matherhospital.org/care-treatment/neurosciences-center/back-and-neck-pain-center/back-neck-pain-center-request-an-appointment-thank-you-page/';
          break;
        case 'Wound Treatment Center-Port Jefferson':
          window.top.location.href =
            'https://www.matherhospital.org/care-treatment/wound-treatment-center/wound-treatment-center-request-an-appointment-thank-you-page/';
          break;
        default:
          window.top.location.href =
            'https://www.matherhospital.org/request-an-appointment-thank-you-page/';
      }

      setSuccess(true);
    } catch {
      setSuccess(false);
    }
    setShowForm(false);
  };

  return (
    <Main>
      {showForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={onSubmit}
        >
          <FormWrapper>
            <Field required label="First Name" type="text" name="firstName" />
            <Field required label="Last Name" type="text" name="lastName" />
            <Field
              required
              label="Phone Number"
              type="maskInput"
              name="phone"
              mask="(999) 999-9999"
            />
            <Field required type="email" name="email" label="Email Address" />
            <Field
              required
              label="Appointment Type"
              type="select"
              options={SERVICE_LOCATIONS}
              name="serviceLocation"
            />
            <ReCaptchaContainer id="re-captcha-container" />
          </FormWrapper>
          <FormWrapper>
            <div>
              <Submit />
            </div>
          </FormWrapper>
        </Formik>
      )}
      {!showForm && (
        <Flex px={4} justifyContent="center">
          <AlertWrapper success={success}>
            <Typography variant="h5" color="white">
              {success ? successMessage : errorMessage}
            </Typography>
          </AlertWrapper>
        </Flex>
      )}
    </Main>
  );
};

export default IndexPage;
