const SERVICE_LOCATIONS = [
  'Behavioral Health - Chemical Dependency Clinic',
  'Behavioral Health - Psych MD Office',
  'Behavioral Health - Mental Health Clinic',
  'Breast Center',
  'Cardiac Rehabilitation',
  'Cat scan',
  'Epilepsy Monitoring Unit (EMU)',
  'EMG/EEG',
  'Hyberbaric Oxygen Therapy',
  'Interventional Radiology',
  'Lung Cancer Screening',
  'Lymphedema',
  'MRI',
  'Nuclear Medicine',
  'Occupational Therapy',
  'Physical Therapy',
  'Radiology',
  'Respiratory',
  'Speech Therapy',
  'Ultrasound',
  'Wound Treatment Center-Port Jefferson',
];

export default SERVICE_LOCATIONS.map((v) => ({ label: v, value: v }));
